import _ from 'lodash';
import jwt from 'jsonwebtoken';
import mock from 'src/utils/mock';

const JWT_SECRET = 'devias-top-secret-key';
const JWT_EXPIRES_IN = '2 days';

const db = {
  usuarioDB: {
    _id: '5e86809283e28b96d2d38537',
    avatar: null,
    isActive: true,
    country: {
      name: 'CUBA',
      callingCodes: [+53],
      alpha2Code: 'CU',
      alpha3Code: 'CUB',
      flag: 'https://restcountries.eu/data/cub.svg'
    },
    saldo: 5,
    email: 'joseph.tula@gmail.com',
    fullname: 'Jose A Tula',
    password: 'Testing.123',
    phone: '+40 777666555',
    movil: '53265345',
    role: ['ADMIN', 'USER', 'RECARGADOR'],
  }
};

mock.onPost('/api/account/login').reply((config) => {
  const { username, password } = JSON.parse(config.data);

  if (username !== 'joseph.tula@gmail.com' || password !== 'Testing.123') {
    return [400, { message: 'Please check your email and password' }];
  }

  const { usuarioDB } = db;

  const token = jwt.sign(
    { id: usuarioDB._id },
    JWT_SECRET,
    { expiresIn: JWT_EXPIRES_IN }
  );

  return [200, { usuarioDB, token }];
});

mock.onGet('/api/account/me').reply((config) => {
  const { Authorization } = config.headers;

  if (!Authorization) {
    return [401, { message: 'Authorization token missing' }];
  }

  try {
    const accessToken = Authorization.split(' ')[1];

    const { id } = jwt.verify(accessToken, JWT_SECRET);

    if (id !== db.user.id) {
      return [401, { message: 'Invalid authorization token' }];
    }

    return [200, { user: db.user }];
  } catch (error) {
    return [401, { message: 'Invalid authorization token' }];
  }
});

mock.onPost('/api/account/profile').reply((request) => {
  const { update } = JSON.parse(request.data);

  _.assign(db.user, update);

  return [200, { user: db.user }];
});

mock.onGet('/api/account/settings').reply(200, {
  settings: {}
});

mock.onGet('/api/account/subscription').reply(200, {
  subscription: {
    name: 'Freelancer',
    price: '5',
    currency: '$',
    proposalsLeft: 12,
    templatesLeft: 5,
    invitesLeft: 24,
    adsLeft: 10,
    hasAnalytics: true,
    hasEmailAlerts: true
  }
});
