import axios from 'src/utils/axios2';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (username, password) => new Promise((resolve, reject) => {
    const params = {
      username,
      password
    }
    axios.post('https://api.users.detooo.com/v1/auth/login', params)
      .then(async (response) => {
        if (response.data.usuarioDB && response.data.usuarioDB.role.includes("RECARGADOR")) {
          this.setSession(response.data.token);
          await axios.get(`https://api.recargas.detooo.com/v1/profile`)
            .then((res) => {
              if (res.data.isActive) {
                const userData = response.data.usuarioDB;
                userData.rechargeProfile = res.data;
                resolve(userData);
              } else {
                reject(response.data.error);
              }
            });
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  changePassword = (oldPassword, newPassword) => new Promise((resolve, reject) => {
    const params = {
      oldPassword,
      password: newPassword
    }
    const config = {
      headers: { Authorization: `Bearer ${this.getAccessToken()}` }
    };
    axios.patch('https://api.users.detooo.com/v1/profile/change_password', params, config)
      .then((response) => {
        if (response.data) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  loginInWithToken = () => new Promise((resolve, reject) => {
    const config = {
      headers: { Authorization: `Bearer ${this.getAccessToken()}` }
    };
    axios.get('https://api.users.detooo.com/v1/profile', config)
      .then(async (response) => {
        if (response.data) {
          await axios.get(`https://api.recargas.detooo.com/v1/profile`)
            .then((res) => {
              if (res.data.isActive) {
                const userData = response.data;
                userData.rechargeProfile = res.data;
                resolve(userData);
              } else {
                reject(response.data.error);
              }
            });
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    return true;
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
