import mock from 'src/utils/mock';

mock.onGet('/api/management/packages').reply(200, {
  data: [{
    isOffer:false,
    isActive:true,
    _id:"5ee962179b0e2d1b4884b904",
    price:10,
    toSend:10,
    type:"cubacel",
    topDay:100,
    topMonth:300,
    description:   
        {
          _id:"5ee962179b0e2d1b4884b905",
          language:"es",
          title:"CUBACEL Plan 10",
          subtitle:"Recibe 10 CUC =1GB de Internet",
          description:"La opcion seleccionada incluye 10 CUC con cada recarga de celular"
        }
    ,
    createdAt:"2020-06-17T00:21:43.667Z",
    updatedAt:"2020-06-17T00:21:43.667Z",
    __v:0
  },
  {
    isOffer:true,
    isActive:true,
    _id:"5ee962179b0e2d1b4884b901",
    price:20,
    toSend:20,
    type:"cubacel",
    topDay:100,
    topMonth:300,
    description:   
        {
          _id:"5ee962179b0e2d1b4884b905",
          title:"CUBACEL Plan 20",
          subtitle:"Recibe 20 CUC =2GB de Internet",
          description:"La opcion seleccionada incluye 10 CUC con cada recarga de celular"
        }
    ,
    createdAt:"2020-06-17T00:21:43.667Z",
    updatedAt:"2020-06-17T00:21:43.667Z",
    __v:0
  }]
});

mock.onGet('/api/management/promotions').reply(200, {
  data: [
    {
      "isActive":true,
      "_id":"5ee78d5ee197af1b0c02f9a7",
      "text":"Si recargas desde el exterior con 20 y hasta 50 recibes un bono de",
      "bono":"40",
      "createdAt":"2020-06-15T15:01:50.401Z",
      "updatedAt":"2020-06-15T15:01:50.401Z",
      "__v":0
    }
  ]
});

mock.onGet('/api/management/recharges').reply(200, {
  Total: 5,
  totalPages: 1,
  currentPage: 1,
  Recargas: [
    {
      _id: "5f06136d045e4b7fbf5e4458",
      user: "5ee255a7fa79dd10f9bce4a7",
      type: "nauta",
      pasarela: "Cubatel",
      amount: 10,
      dest: "test@nauta.com.cu",
      merchantTransactionId: "40779c93-3ac6-4ccc-b317-11852bac5e83",
      status: "success",
      description: "Transacción completada correctamente ",
      serverResponse: {
        data: {
          transactionId: "40779c93-3ac6-4ccc-b317-11852bac5e83",
          provisionStatus: "COMPLETED",
          description: "Transacción completada correctamente ",
          apiDescription: null,
          noRecargado: "test@nauta.com.cu",
          reTryFailedTopUp: true
        }
      },
      createdAt: "2020-07-08T18:41:49.262Z",
      updatedAt: "2020-07-08T18:41:49.262Z",
      __v: 0
    },
    {
      _id: "5f0611f4045e4b7fbf5e4457",
      user: "5ee255a7fa79dd10f9bce4a7",
      type: "nauta",
      pasarela: "Cubatel",
      amount: 22,
      dest: "50000000",
      merchantTransactionId: "a034f101-fa64-4df1-baab-ec52ecde8681",
      status: "pending",
      description: "Transacción completada correctamente ",
      serverResponse: {
        data: {
          transactionId: "a034f101-fa64-4df1-baab-ec52ecde8681",
          provisionStatus: "COMPLETED",
          description: "Transacción completada correctamente ",
          apiDescription: null,
          noRecargado: "50000000",
          reTryFailedTopUp: true
        }
      },
      createdAt: "2020-07-08T18:35:32.619Z",
      updatedAt: "2020-07-08T18:35:32.619Z",
      __v: 0
    }
  ]
});

mock.onGet('/api/management/recharges/1').reply(200, {
  data:  {
    _id:"5ef623ade3bdc2330cb2d55e",
    user:"5ee1ba67fa79dd10f9bce498",
    type:"nauta",
    pasarela:"Cubatel",
    amount:10,
    dest:"test@nauta.com.cu",
    merchantTransactionId:"7ad1c239-5cea-4ef2-a23a-06cffbfc7e17",
    serverResponse:
        {
          data:
            {
              transactionId:"7ad1c239-5cea-4ef2-a23a-06cffbfc7e17",
              provisionStatus:"COMPLETED",
              description:"Transacción completada correctamente ",
              apiDescription:null,
              noRecargado:"test@nauta.com.cu",
              reTryFailedTopUp:true
            }
        },
    createdAt:"2020-06-26T16:34:53.637Z",
    updatedAt:"2020-06-26T16:34:53.637Z",
    __v:0
  }
});

mock.onGet('/api/management/provinces').reply(200, {
  data: [
    {
      _id: "5ed67d92af617d3cd4f36a84",
      nombre: "Ciego de Ávila",
      slug: "ciego-de-avila",
      __v: 0
    },
    {
      _id: "5ed67a87af617d3cd4f36a76",
      nombre: "La Habana",
      slug: "la-habana",
      __v: 0
    },
    {
      _id: "5ed67a5aaf617d3cd4f36a74",
      nombre: "Pinar del Río",
      slug: "pinar-del-rio",
      __v: 0
    },
    {
      _id: "5ed67b1baf617d3cd4f36a79",
      nombre: "Villa Clara",
      slug: "villa-clara",
      __v: 0
    },
    {
      _id: "5ed67ab7af617d3cd4f36a78",
      nombre: "Matanzas",
      slug: "matanzas",
      __v: 0
    },
    {
      _id: "5ed67e07af617d3cd4f36a8e",
      nombre: "Granma",
      slug: "granma",
      __v: 0
    },
    {
      _id: "5ed67e0faf617d3cd4f36a90",
      nombre: "Guantánamo",
      slug: "guantanamo",
      __v: 0
    },
    {
      _id: "5ed67a9faf617d3cd4f36a77",
      nombre: "Mayabeque",
      slug: "mayabeque",
      __v: 0
    },
    {
      _id: "5ed67a75af617d3cd4f36a75",
      nombre: "Artemisa",
      slug: "artemisa",
      __v: 0
    },
    {
      _id: "5ed67e04af617d3cd4f36a8d",
      nombre: "Holguín",
      slug: "holguin",
      __v: 0
    },
    {
      _id: "5ed67e12af617d3cd4f36a91",
      nombre: "Isla de la Juventud",
      slug: "isla-de-la-juventud",
      __v: 0
    },
    {
      _id: "5ed67d85af617d3cd4f36a83",
      nombre: "Sancti Spíritus",
      slug: "sancti-spiritus",
      __v: 0
    },
    {
      _id: "5ed67b34af617d3cd4f36a7a",
      nombre: "Cienfuegos",
      slug: "cienfuegos",
      __v: 0
    },
    {
      _id: "5ed67debaf617d3cd4f36a8b",
      nombre: "Camagüey",
      slug: "camaguey",
      __v: 0
    },
    {
      _id: "5ed67dfeaf617d3cd4f36a8c",
      nombre: "Las Tunas",
      slug: "las-tunas",
      __v: 0
    },
    {
      _id: "5ed67e0baf617d3cd4f36a8f",
      nombre: "Santiago de Cuba",
      slug: "santiago-de-cuba",
      __v: 0
    },
    {
      _id: "5edd743bf753961214f3fc03",
      nombre: "Puerto Padre",
      slug: "puerto-padre"
    }
  ]
});